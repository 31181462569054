<template>
  <win-block>
    <template #win-title-left>Импорт</template>


    <v-row no-gutters justify="center" align="center">
      <v-col cols="12" class="pl-1 ">
        <v-btn color="primary" dark small
               :loading='wait_download'
               @click="btnDownload()"
        >
          Скачать
          <v-icon right dark>mdi-cloud-download</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </win-block>
</template>

<script>

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
  },
  data: () => ({
    wait_download: false,
  }),
  methods: {
    btnDownload() {
      // this.load_dwnl = true
      // https://tsn.verlan.ru/static/site/ tsn.verlan.ru/server/api/v1/project/download
      this.axios.get(this.$store.state.domen + this.$url.server_project_download, {
        responseType: 'blob',
        zLoader: [this, 'wait_download']
      })
          .then((response) => {
            const contentDisposition = response.headers['content-disposition'];
            // для получения headers['content-disposition'] на сервере должно быть прописано: response['Access-Control-Expose-Headers'] = 'Content-Disposition'
            let fileName = 'file';
            if (contentDisposition) {
              // const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
              // if (fileNameMatch.length === 2)
              //   fileName = fileNameMatch[1];
              fileName = response.headers["content-disposition"].split(
                  "filename="
              )[1];
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            // link.setAttribute('download', 'file.xlsx'); //or any other extension
            link.setAttribute('download', fileName);
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            link.remove();
            // this.load_dwnl = false
          });


      // // axios({
      // //   url: 'http://api.dev/file-download', //your url
      // //   method: 'GET',
      // //   responseType: 'blob', // important
      // // }).then((response) => {
      //   const url = window.URL.createObjectURL(new Blob([response.data]));
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute('download', 'file.pdf'); //or any other extension
      //   document.body.appendChild(link);
      //   link.click();
      // });

    },
  }
}
</script>


<style lang="scss">
.z-spin {
  animation: spin-animation 1.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
</style>